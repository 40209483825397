<template>
  <div class="interval">
    <md-result-page
      :img-url="require('@/assets/abd/image/null.png')"
      v-if="reportok === ''"
      subtext="要不然刷新试试？"
    />
    <div v-if="reportok === 'ok'">
      <div class="comlogo" :style="'background:'+getStorage('theme','')" v-if="policyData.baseinfo.logo&&policyData.baseinfo.logo!==''">
        <div class="logo">
          <img :src="policyData.baseinfo.logo" alt="" />
        </div>
       </div>
      <div class="title bg_img" :style="'background:'+getStorage('theme','')">
        <h3>{{ mainpolicyname }}</h3>
        <h4>保单号：{{ mainpolicynum }}</h4>
      </div>
      <div class="bd">
        <div class="person wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div
            class="item"
            v-for="(item, index) in policyData.abdPolicynoPerson"
            :key="index"
          >
            <acrdion :index="index" :ismore="item.benetype !== '00'">
              <div class="tbstitle">
                {{ persontypes[item.rytype] }}:
                {{ item.benetype == "00" ? "法定" : item.pname }}
              </div>
              <template #content>
                <div class="row tbs">
                  <div class="font_grey">
                    性别:
                    <span class="font_grey">{{ sexs[item.psex] }}</span>
                  </div>
                  <div class="font_grey">
                    出生日期:
                    <span class="font_grey">{{ item.birthdate || item.pbirthdate}}</span>
                  </div>
                </div>
                <div class="pad">
                  <div class="font_grey">
                    证件类型:
                    <span class="font_blue" :style="'color:'+getStorage('theme','')">{{
                      idType[item.cardtype - 10]
                    }}</span>
                  </div>
                </div>
                <div class="pad">
                  <div class="font_grey">
                    证件号码:
                    <span class="font_blue" :style="'color:'+getStorage('theme','')">{{ item.cardno }}</span>
                  </div>
                </div>
              </template>
            </acrdion>
          </div>
        </div>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont iconbaoxiandingdan" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">险种构成</div>
        </div>
        <div class="wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div
            class="item"
            v-for="(item, index) in policyData.abdPolicynoBasemsgs"
            :key="index"
          >
            <acrdion :index="index">
              <div class="bt_xzgc">
                {{ item.prodname }}
              </div>
              <template #content>
                <div class="row tbs">
                  <div class="col">
                    保险期间:
                    <span class="col" :style="'color:'+getStorage('theme','')">{{ item.liabdate }}</span>
                  </div>
                  <div class="col">
                    缴费期限:
                    <span class="col" :style="'color:'+getStorage('theme','')">{{ item.yearnum }}</span>
                    {{ item.paycode }}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    保险金额:
                    <span class="col" :style="'color:'+getStorage('theme','')">{{ item.amount }}</span
                    >元
                  </div>
                  <div class="col">
                    保险费:
                    <span class="col" :style="'color:'+getStorage('theme','')">{{ item.mount }}</span
                    >元
                  </div>
                </div>
                <div class="dutywaiver" v-if="item.noduty">
                  <p @click.stop="lookdutywaiver(item)">责任免除<md-icon name="arrow-right" size="md"></md-icon></p>
                </div>
              </template>
            </acrdion>
          </div>
        </div>
        <div class="infos">
          <span class="dutyExpet" v-if="pageData.risksthat"
            >险种说明：{{ pageData.risksthat }}</span
          >
        </div>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont iconzerenzhenduandian" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">保险责任</div>
        </div>
        <div class="changend"><input type="range" name="yeardu" id="range" v-model="bznd" min="1" max="105" ref="year">第 <input type="number" v-model="bznd">年</div>
        <!-- <md-slider v-model="bznd" :format="formatbznd" :min='1' :max='105'></md-slider> -->
        <div class="wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div class="caption1">
            <div class="text1 deta"><span>保险责任</span></div>
            <div class="deta je">保险金额<br />到期时间</div>
          </div>
          <div class="count">
            <div
              class="items"
              v-for="(item, index) of policyData.abdPolicynoLiab"
              :key="index"
            >
              <div class="tl" @click="showDetail(item)">
                <span>{{ item.liabdesc }}</span>
                <i
                  class="leftdiv iconfont iconicon"
                  v-if="item.liabExtent&&item.liabExtent !== '[]'"
                ></i>
              </div>
              <div>
                <i
                  :style="'color:'+getStorage('theme','')"
                  v-if="item.liabexplain && item.liabexplain !== 'null'"
                  class="leftdiv iconfont iconbangzhushuoming showduty"
                  @click="showDuty(item, index)"
                ></i>
              </div>
              <div class="cite">
                <div
                  class="price"
                  :style="'color:'+getStorage('theme','')"
                  v-if="item.liabexp != '0' && item.liabAtype == '2'"
                >
                  {{ item.liabexp +' '+ (item.untils ? item.untils : '') }}
                </div>
                <div
                  class="price"
                  :style="'color:'+getStorage('theme','')"
                  v-show="item.liabatype !== '2'"
                  v-else-if="item.liabexp != '0' && item.liabexp != ''"
                 >
                  ￥{{formatInteger(calcbaoe(item.liabexp,item.ratenum,item.jsfs,bznd).toFixed(2)) + ' '+ (item.untils ? item.untils : '')}}
                  <!-- ￥{{ (item.liabexp? item.liabexp : '')+' '+(item.untils ? item.untils : '')}} -->
                </div>
                <div
                  class="price"
                  :style="'color:'+getStorage('theme','')"
                  v-show="item.liabatype === '2'"
                 >
                  {{ item.liabexp +' '+ (item.untils ? item.untils : '')}}
                </div>
                <div
                  class="tiem"
                  v-if="
                    item.liabexp != '0' &&
                      item.liabAtype != '2' &&
                      item.liabexp != ''
                  "
                >
                  {{ item.liabdate? item.liabdate : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont iconmianfei" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">责任免除</div>
        </div>
        <div class="wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <pre class="contwords">{{ pageData.DutyExempt }}</pre>
          <pre class="contwords noremarks" v-if="!pageData.DutyExempt">
暂无数据</pre
          >
        </div>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont icon1" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">缴费信息</div>
        </div>
        <div class="wrapper info" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div class="infos">
            <div class="name">保费（元）：</div>
            <div class="red" :style="'color:'+getStorage('theme','')">{{ policyData.baseinfo.mount }}</div>
          </div>
          <div class="infos">
            <div class="name">缴费方式:</div>
            <div class="blue" :style="'color:'+getStorage('theme','')">{{ policyData.baseinfo.paycode }}</div>
          </div>
          <div class="infos">
            <div class="name">缴费期间:</div>
            <div class="blue" :style="'color:'+getStorage('theme','')" v-if="policyData.baseinfo.paycode == '年缴'">
              {{ policyData.baseinfo.yearnum }}年
            </div>
            <div class="blue" :style="'color:'+getStorage('theme','')" v-else-if="policyData.baseinfo.paycode == '月缴'">
              {{ policyData.baseinfo.yearnum }}月
            </div>
            <div class="blue" :style="'color:'+getStorage('theme','')" v-else-if="policyData.baseinfo.paycode == '季缴'">
              {{ policyData.baseinfo.yearnum }}季
            </div>
            <div class="blue" :style="'color:'+getStorage('theme','')" v-else>{{ policyData.baseinfo.yearnum }}</div>
          </div>
          <div class="infos">
            <div class="name">保险期间:</div>
            <div class="blue" :style="'color:'+getStorage('theme','')">{{ policyData.baseinfo.liabdate }}</div>
          </div>
          <div class="infos">
            <div class="name">保险公司:</div>
            <div class="blue" :style="'color:'+getStorage('theme','')">{{ policyData.baseinfo.shortname }}</div>
          </div>
          <div class="infos">
            <div class="name">客服电话:</div>
            <div class="blue" :style="'color:'+getStorage('theme','')">{{ policyData.baseinfo.kftel }}</div>
          </div>
        </div>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont iconmingpian1" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">经纪人名片</div>
        </div>
        <div class="wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div class="infos">
            <div class="name">姓名:</div>
            <div class="man">{{ pageData.abdMembUser.empname }}</div>
          </div>
          <div class="infos">
            <div class="name">手机号:</div>
            <div>
              <a class="man" :href="'tel:' + pageData.abdMembUser.empphone">{{
                pageData.abdMembUser.empphone
              }}</a>
            </div>
          </div>
        </div>
        <dig :show.sync="isupdate" title="编辑经纪人名片" :istext="false">
          <md-field>
            <md-input-item
              title="姓名"
              v-model="nickname"
              clearable
              placeholder="请输入经纪人姓名"
            />
            <md-input-item
              title="手机"
              v-model="mobile"
              clearable
              placeholder="请输入经纪人手机号"
            />
          </md-field>
          <md-button
            :style="'background:'+getStorage('theme','')"
            size="large"
            class="context_bg"
            type="primary"
            @click="submitmsg"
            >提交</md-button
          >
        </dig>
      </div>
      <div class="bd">
        <div class="caption">
          <div class="icon">
            <span class="leftdiv iconfont iconbeizhuyitianxie" :style="'color:'+getStorage('theme','')"></span>
          </div>
          <div class="text" :style="'color:'+getStorage('theme','')">备注</div>
          <div class="bj_btn">
            <p
              class="iconfont iconbeizhu2 remark_color"
              @click="!noShow ? showDig(1) : ''"
              v-if="!noShow"
            ></p>
          </div>
        </div>
        <div class="wrapper" :style="'border:'+'1px solid '+ getStorage('theme','')">
          <div class="texts" placeholder="请输入">
            <pre class="contwords">{{ text }}</pre>
            <pre class="contwords noremarks" v-if="!text || text === ''">暂无数据</pre>
          </div>
        </div>
      </div>
      <dig
        :title="
          isShare
            ? '报告访问链接及密码'
            : isText
            ? textMsg[toggleDig]
            : '责任详情'
        "
        :istext="isText"
        @confirm="question"
        :text="toggleDig ? text : ''"
        :show.sync="isDetail"
      >
        <div v-if="isShare" class="links">
          <div class="txt">
            【保单整理】您有一份【{{mainpolicyname}}】保单解析报告,请戳以下链接并输入密码查看：{{redirect_uri}}/sharereport
            (验证码：{{
              pageData.abdReportLists.yzm
            }})。请您妥善保管本消息，切勿随意转发给其他人。
          </div>
          <div class="bt">
            <md-button
              size="large"
              :disabled="sharebtn"
              data-clipboard-target="#foo"
              class="copy context_bg copybtn"
              type="primary"
              >{{ btnText }}</md-button
            >
          </div>
          <div class="prompt">
            点“复制”后，在与客户本人（客户保单的投保人或被保人）的微信的聊天窗口中“粘贴”此消息并发送即可
          </div>
          <div id="foo">
            【保单整理】您有一份【{{mainpolicyname}}】保单解析报告,请戳以下链接并输入密码查看：
            {{
              `${redirect_uri}/sharereport?t=${params} (验证码：${pageData.abdReportLists.yzm})`
            }}。请您妥善保管本消息，切勿随意转发给其他人。
          </div>
        </div>
        <ul v-else class="lists">
          <li v-for="(item, index) of disdetail" :key="index">
            <div class="dutyTitle" style="padding: 6px 0">
              <span v-if="disdetail.length > 1">{{ item.disname }}</span>
            </div>
            <span v-for="(bz_item, bz_index) in item.diseases" :key="bz_index">
              <span style="line-height: 26px">{{ bz_index + 1 }}.</span>
              <span style="line-height: 26px">{{ bz_item.disname }}</span>
              <br v-if="bz_item.undiseasesname" />
              <span v-if="bz_item.undiseasesname&&bz_item.undiseasesname.length!=0" style="line-height: 26px">
                不保病种：<br />
                <span v-for="(undiseasesname_item,undiseasesname_index) in bz_item.undiseasesname" :key="undiseasesname_index">
                  {{undiseasesname_item}}<br />
                  <!-- <span v-if="undiseasesname_index === bz_item.undiseasesname.length-1">）</span> -->
                </span>
              </span>
            </span>
          </li>
        </ul>
      </dig>
      <dig :show.sync="isDuty" :istext="isText" title="责任说明">
        <div class="lists">
          <span>{{ dutyExplain }}</span>
        </div>
      </dig>
      <!-- <div class="hw_sty" @click="toShare" v-if="!noShow">
        <span class="iconfont iconfenxiang"></span>
        <span>分享</span>
      </div> -->
      <div class="pdfbox" v-if="pdfshow">
        <iframe
          :src="pageData.tiaokuanurl"
          frameborder="0"
          name="_blank"
          :width="deviceWidth"
          :height="deviceHeight"
          scrolling="auto"
          style="-webkit-overflow-scrolling:touch"
        ></iframe>
        <!-- <a :href="pageData.tiaokuanurl" target="_blank"></a> -->
        <!-- <pdf class="showPdf" :src="pageData.tiaokuanurl" ></pdf> -->
        <!-- <div class="back" @click="pdfclose()">
              <img src="../../assets/image/goback.png" class="back_img">
            </div> -->
      </div>
    </div>
    <dig :show.sync="showwaiver" :istext="false" title="责任免除">
        <span class='contwords'>{{ dutywaiver }}</span>
    </dig>
    <div class="navsetting" v-if="!noShow&&user.rytype!=='Y'&&fromwhere=='app'">
      <div class="backbtn" :style="'height:'+'33%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
      <div class="backtohome" :style="'height:'+'33%'" @click="backtohomepage" v-if="personkey==='0'">
        <span class="iconfont iconhome"></span>
      </div>
      <div class="shareicon" :style="'height:'+'33%'" @click="toShare">
        <span class="iconfont iconiconfontfenxiang2"></span>
      </div>
    </div>
    <div class="navsetting2" v-if="user.rytype==='Y'&&!noShow">
       <div class="backbtn" :style="'height:'+'100%'" @click="backtopage">
        <span class="iconfont iconback"></span>
      </div>
    </div>
  </div>
   <!--  <div class="backbtn" @click="backtopage" v-if="!noShow">back</div>
    <div class="backtohome" @click="backtohomepage" v-if="!noShow&&personkey==='0'">home</div> -->
  </div>
</template>
<script>
import acrdion from '@/components/accordion'
import dialog from '@/components/dialog'
import {
  queryPolicy,
  policydoubt,
  updatemsg
} from '@/api/abd/customer'
import { editpolicyremarks, looksharereport } from '@/api/abd/family'
import ClipboardJS from 'clipboard'
import { Base64 } from 'js-base64'
import { Toast } from 'mand-mobile'
import config from '@/config'
import { wechatshare } from '@/lib/wechat_share'
import { getStorage,AppShare,calcbaoe,formatInteger } from '@/lib/util'
import { DoRecord } from '@/api/abt/customerOperation/common/index'
import initWebSocket from '@/mixins/websock'
import store from '@/store'

const { redirect_uri } = config
export default {
  mixins: [initWebSocket],
  components: {
    acrdion,
    dig: dialog
  },
  data () {
    return {
      sharebtn: false,
      showwaiver: false,
      dutywaiver: '',
      reportok: 'ok',
      text: '',
      isDetail: false,
      policyData: {
        abdPolicynoBasemsgs: [{ classcode: '' }],
        baseinfo: {}
      },
      textMsg: ['添加疑问', '修改备注'],
      persontypes: { '01': '投保人', '02': '被保人', '03': '受益人' },
      idType: [
        '身份证',
        '户口簿',
        '军官证',
        '港澳居民来往内地通行证',
        '台湾居民来往大陆通行证',
        '护照',
        '出生证',
        '其他'
      ],
      sexs: { '0': '未知性别', '1': '女', '2': '男', '9': '未说明性别' },
      cardData: {},
      pageData: {
        abdMembUser: {
          empno: null,
          id: null,
          mobile: null,
          nickname: null,
          openid: null,
          status: null,
          vcardurl: null,
          yzmcode: null,
          yzmtime: null
        },
        abdReportLists: {},
        question: null,
        reportdata: {}
      },
      disdetail: [],
      isShow: true,
      isText: false,
      isShare: false,
      params: '',
      btnText: '一键复制',
      noShow: false,
      toggleDig: 0,
      isupdate: false,
      nickname: '',
      mobile: '',
      deviceWidth: '',
      deviceHeight: '',
      pdfshow: false,
      isDuty: false,
      dutyExplain: '',
      shortUrl: '',
      // isShowDuty: true
      /* pc端跳进来不能分享 */
      urltype: '',
      /* 行为追踪 */
      user: {},
      empno: '',
      rytype: '',
      suserid: '',
      yzm: '',
      personkey: '',
      mainpolicyname: '',
      mainpolicynum: '',
      batchno: '',
      fromwhere:'',
      bznd: 1
    }
  },
  created () {
    this.calcbaoe = calcbaoe
    this.formatInteger = formatInteger
    this.fromwhere = this.$route.query.fromwhere
    this.personkey = this.$route.query.personkey
    this.user = getStorage('u_s', {})
    // if (this.user.rytype != 'W') {
    //   // 不是业务员
    //   wechatshare('', '', '', '', '', '', true)
    // }
    this.empno = this.$route.query.empno && this.$route.query.empno !== '' ? this.$route.query.empno : this.user.empno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    if(this.rytype === 'Y') this.rytype = 'W'
    if (this.personkey === '1') {
      this.rytype = 'W'
    }
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    if (this.$route.query.type || this.$route.query.sharetype) {
      this.noShow = true
    }
    queryPolicy({
      yzm: this.$route.query.yzm,
      reportno: this.$route.query.reportno,
      comid: this.$route.query.comid
      // yzm: this.$route.query.code || this.$superCode
    }).then(res => {
      if (!res.data.data.reportdata) {
        this.reportok = ''
      }
      store.state.app.isloading = false
      this.pageData = res.data.data
      this.policyData = res.data.data.reportdata
      this.$forceUpdate()
      this.text = res.data.data.abdReportLists.remarks
      this.yzm = this.pageData.abdReportLists.yzm
      this.policyData.abdPolicynoBasemsgs.some(item => {
        if (item.appf === '1') {
          this.mainpolicyname = item.prodname
          this.mainpolicynum = item.policyno
          this.batchno = item.batchno
          return true
        }
      })
      if (!this.$route.query.type) {
        let info = getStorage('u_s', {})
        // comid=${this.getComid}&reportno=${row.reportno}&policyno=${row.policyno}&yzm=abd666&type=pc
        let shareurl = `${redirect_uri}/policydetail?comid=${this.$route.query.comid}&reportno=${this.$route.query.reportno}&policyno=${this.$route.query.policyno}&yzm=${this.yzm}&empno=${this.empno}&recordtype=11&suserid=${this.user.userid}&rytype=${this.rytype}&sharetype=00`
        if (!this.$route.query.type&&this.fromwhere!=='app'&&this.user.rytype === 'W'&&!this.$route.query.sharetype) {
          this.behaviorRecord()
        }
        if (this.$route.query.sharetype && this.user.userid !== this.suserid) { // 如果是查看保单客户
          looksharereport({ userid: this.user.userid, reportno: this.$route.query.reportno, yzm: this.$route.query.yzm, comid: this.$route.query.comid }).then(res => { console.log(res) })
          if(this.fromwhere!=='app'){
          this.behaviorRecord()
         }
        }
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '2',
          btagcode: '11',
          rytype: this.rytype,
          remark: this.mainpolicyname,
          scmap: {
            reportno: this.policyData.reportno
          }
        }
        if (this.user.rytype != 'W'&&this.fromwhere!=='app') {
          // 不是业务员
          wechatshare('', '', '', '', '', '', true)
        } else {
          if(this.fromwhere!=='app'){
            wechatshare(
            this.mainpolicyname + '保单解析报告',
            '您的好友' + '【' + info.nickname + '】' + '邀请您查看【' + this.mainpolicyname + '】保单解析报告',
            info.headimg,
            encodeURI(shareurl),
            redirect_uri,
            fxstr
          )
          }
        }
      }
      this.$route.query.comid = this.user.comid
      this.$route.query.suserid = this.suserid
      this.$route.query.empno = this.empno
      this.params = Base64.encodeURI(
        JSON.stringify({
          path: {
            p: this.$route.path,
            query: this.$route.query
          },
          verify: this.pageData.abdReportLists.yzm
        })
      )
    })

    this.isShow = !this.$route.query.code
    new ClipboardJS('.copy').on('success', e => {
      this.btnText = '复制成功'
      this.sharebtn = true
      e.clearSelection()
    })
  },
  mounted () {
    this.deviceWidth = document.documentElement.clientWidth
    this.deviceHeight = document.documentElement.clientHeight
    // eslint-disable-next-line no-irregular-whitespace
    $(document).on('focusout', function () {
      // 软键盘收起的事件处理
      window.scroll(0, 0)
    })
  },
  methods: {
    // 修改经纪人信息
    showUpdateMsg () {
      if (!this.noShow) {
        this.isupdate = true
        this.nickname = this.pageData.abdMembUser.nickname
        this.mobile = this.pageData.abdMembUser.mobile
      }
    },
    // 保存经纪人信息
    submitmsg () {
      let date = {
        abdMembUser: {
          nickname: this.nickname,
          mobile: this.mobile
        }
      }
      updatemsg(date).then(res => {
        this.pageData.abdMembUser.nickname = this.nickname
        this.pageData.abdMembUser.mobile = this.mobile
      })
      this.isupdate = false
    },
    toShare () {
      const jsonstring = {
        url: `${redirect_uri}/policydetail?comid=${this.$route.query.comid}&reportno=${this.$route.query.reportno}&policyno=${this.$route.query.policyno}&yzm=${this.yzm}&empno=${this.empno}&recordtype=11&suserid=${this.user.userid}&rytype=${this.rytype}&sharetype=00`,
        title: this.mainpolicyname + '保单解析报告',
        description: '您的好友' + '【' + this.user.empname + '】' + '邀请您查看【' + this.mainpolicyname + '】保单解析报告',
        logoUrl: '' 
      }
      AppShare(jsonstring)
      // const shareparams = window.JSON.stringify(jsonstring)
      // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      //   window.webkit.messageHandlers.ZNBGShare.postMessage(shareparams)
      // }else {
      //   window.ZNBGInterface.share(shareparams)
      // }
      // this.btnText = '一键复制'
      // this.isText = false
      // this.isShare = true
      // this.isDetail = !this.isDetail
      // let data = {
      //   url: `http://abdc.abtpt.cn/analysis?t=${this.params} (验证码：${this.pageData.abdReportLists.yzm})`
      // }
      // getShorturl(data).then(res => {
      //   this.shortUrl = res.data
      // })
    },
    totiaokuan () {
      this.pdfshow = true
      // top.location.href = this.pageData.tiaokuanurl
      console.log(this.pageData.tiaokuanurl)
    },
    pdfclose () {
      this.pdfshow = false
    },
    showDetail (item) {
      if (item.liabExtent !== '[]') {
        this.isShare = false
        this.isText = false
        this.disdetail = item ? JSON.parse(item.liabExtent) : []
        // console.log(this.disdetail)
        this.disdetail.forEach(item => {
          item.diseases.forEach(inx => {
            if (inx.undiseases) {
              inx.undiseasesname =[]
              let diseasesname = []
              inx.undiseases.forEach(i => {
                if (i.undisdesc) {
                  if(i.undisname === '-'){
                    diseasesname.push(i.undisdesc)
                  }else {
                    diseasesname.push(i.undisname + '：' + i.undisdesc)
                  }
                } else {
                  diseasesname.push(i.undisname)
                }
              })
              inx.undiseasesname = diseasesname
            }
          })
        })
        this.isDetail = !this.isDetail
      }
      // this.isDetail = !this.isDetail
    },
    // 显示保险责任
    showDuty (item, index) {
      this.isText = false
      this.isDuty = !this.isDuty
      if (item.liabexplain && item.liabexplain !== 'null') {
        this.dutyExplain = item.liabexplain
      }
    },
    showDig (flag) {
      if (flag === 0 && this.pageData.question) {
        Toast.info('你已添加疑问')
        return
      }
      this.toggleDig = flag
      this.isText = true
      this.isDetail = !this.isDetail
      this.isShare = false
    },
    question (text) {
      if (text === '') {
        Toast.info('请填写信息')
        return
      }
      if (this.toggleDig) {
        editpolicyremarks({
          remarks: text,
          reportno: this.$route.query.reportno,
          comid: getStorage('u_s', {}).comid
        }).then(res => {
          Toast.info('修改备注成功')
          this.text = res.data.data.remarks
        }).finally(() => { this.toggleDig = 0 })
      } else {
        policydoubt({
          abdPolicynoQuesttion: {
            qdesc: text,
            reportno: this.$route.query.reportno
          }
        }).then(() => {
          Toast.info('已提交')
          this.pageData.question = {}
        })
      }
    },
    lookdutywaiver (item) {
      this.showwaiver = true
      this.dutywaiver = item.noduty
    },
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        otype: '1',
        btagcode: '11',
        rytype: this.rytype,
        remark: this.mainpolicyname,
        scmap: {
          reportno: this.policyData.reportno
        }
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    },
    backtopage(){ 
      if(this.$route.query.from === 'analysis'){
        this.$router.push({path:'/analysis',query:{fromwhere: this.$route.query.fromwhere }})
      }else{
        this.$router.push({path:'/upload',query:{
          familyno: this.$route.query.familyno,
          empno: this.$route.query.empno,
          personkey: this.$route.query.personkey,
          familyname: this.$route.query.familyname,
          fromwhere: this.$route.query.fromwhere
        }})
    }},
    backtohomepage(){this.$router.push({path:'/bdtg',query:{fromwhere: this.$route.query.fromwhere,bdtgflush: true }})}
  }
}
</script>
<style scoped lang="stylus">
#foo {
  opacity 0
  height: 0
}
.links .txt {
  border: 1px dashed #81b7da;
  padding: 40px 20px;
  font-size: 36px;
}
.links .bt {
  padding: 20px;
}
.links button {
  background: #036eb5;
}
.prompt {
  color: #848484;
  font-size: 36px;
}
.dutyTitle {
  text-align: center;
}
.lists {
  padding: 0 48px;
  height: 100%;
  overflow: scroll;
}
.lists li {
  line-height: 40px;
}
.bd {
  background-color: #ffffff;
  padding: 51px;
}
.shade {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.footer {
  text-align: center;
  padding: 30px 20px;
}
.btns {
  text-align: center;
  padding-bottom: 116px;
  padding-top: 49px;
}
.btns button:last-child {
  background: #3296fa;
  color: #fff;
}
.btn {
  height: 91px;
  color: #fff;
  font-size: 28px;
}
.texts {
  min-height: 247px;
  padding: 10px 10px;
  border-radius: 10px;
  font-size: 30px;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 50px;
}
.dutyExpet {
  color: #848484;
  font-size: 20px;
  margin-left: 20px;
}
.infos {
  display: flex;
  align-items: center;
  font-size: 30px;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
.infos .blue{
  font-size: 35px
}
.infos:last-child {
  border-color: transparent;
}
.infos div:frist-child {
  width: 30%;
}
.infos div:last-child {
  text-align: right;
  flex: 1;
}
.items {
  display: flex;
  align-items: center;
  font-size: 30px;
  padding: 20px 0px;
}
.items .tl {
  /* padding: 10px 10px; */
  position: relative;
  z-index:99;
  padding-left: 10px;
  min-height: 50px;
  border: 1px solid #b6c2db;
  border-radius: 10px;
  position: relative;
  margin-right: 20px;
  width: 65%;
  cursor: pointer;
}
.tl i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  font-size: 30px;
  color: #bec1c3; /* 保险责任框中小图标颜色 */
}
.tl span {
  display: inline-block;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 10px auto;
}
.iconbangzhushuoming {
  color: #969696;
  font-size: 35px;
}
.cite {
  text-align: center;
  width: 33%;
}
.cite .price,
.price {
  color: color-primary;
  font-size: 30px;
}
.tiem {
  color: #cccccc;
  font-size: 28px;
}
.cite .tiem {
  font-size: 30px;
}
.title {
  padding-top: 86px;
  background-color: #ffffff;
  /* padding-left: 50px; */
  padding: 50px 50px;
  margin-bottom: 35px;
}
.title h3 {
  font-size: 38px;
  color: #ffffff;
  font-family: PingFang;
  /* font-weight: bold; */
}
.title h4 {
  margin-top: 32px;
  font-size: 34px;
  color: #ffffff;
  font-family: PingFang;
  /* font-weight: 400; */
}
.interval {
  color: #333;
  background-color: #edf0f2;
  width: 100vw;
  height: 100vh;
  overflow-x:hidden
}
.bg_img {
  // background-image: url("../../../../assets/abd/image/bd_banner.jpg");
  background-color color-primary
  /* height: 259px; */
}
.bg_img{
 p {
   height 100px
}
}

.bd {
  margin-bottom: 35px;
}
.wrapper {
  background-color: #ffffff;
  border: 1px solid color-primary; /*边框*/
  border-radius: 10px;
  padding: 0 40px;
  margin-bottom: 20px;
}
.item {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
.item:last-child {
  border-color: transparent;
}
.item ul li {
  line-height: 50px;
  font-size: 24px;
  color: #969696;
}
/* .bt_xzgc {
      width: 480px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    } */
.tbs {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ebebeb; /* 投被受的下划线 */
}
.caption {
  display: flex;
  align-items: center;
  color: #3296fa;
  padding-bottom: 55px;
}
.caption .iconfont{
  font-size: 20px;
}
.caption1 {
  display: flex;
  align-items: center;
  color: #3296fa;
  margin-top: 20px;
}
.leftdiv {
  display: inline-block;
  position: relative;
  height: 30px;
  font-size: 36px
  color color-primary
}
.showduty{
  font-size: 40px
}
.text {
  width: 65%;
  font-size: 40px;
  margin-left: 5px;
  /* font-weight: bold; */
  letter-spacing: 3px;
  color color-primary
}
.text1 {
  width: 65%;
  font-size: 31px;
  margin-left: 5px;
}
.deta {
  color: #383838;
  font-size: 28px;
  text-align: center;
}
.je {
  width: 30%;
  padding-left: 50px;
}
.row {
  display: flex;
  font-size: 30px;
  justify-content: space-between;
  margin-bottom: 20px;
}
.row:last-child {
  margin: 0;
}
.row .col span {
  color: color-primary;
}
.row .col {
  /* flex: 1; */
  color: #969696;
  font-size: 34px;
}
.row .col:last-child {
  text-align: right;
}
.red {
  color: #d4484c;
}
.blue {
  color: color-primary;
  font-size: 26px;
}
.man {
  color: #969696;
}
.bj_btn {
  color: black;
  margin-left: 25%;
}

.font_blue {
  color: color-primary;
  font-size: 34px;
}
.font_grey {
  font-size: 34px;
  color: #969696;
}
.pad {
  margin-bottom: 20px;
}
.share {
  background-color: #2fb2fa;
}
.hw_sty {
  height: 70px;
  width: 170px;
  position: fixed;
  background-color: color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  right: 0;
  bottom: 40%;
  border-radius: 50px 0 0 50px
}
.iconfenxiang{
  font-size: 40px;
  display: inline-block;
  display: flex;
  height: 60px;
  align-items: center;
}
.contwords {
  min-height: 180px;
  font-size: 35px;
  line-height: 50px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.noremarks {
  display: flex;
  justify-content: center;
  color: #aaa;
  font-size: 35px;
}
.tksm {
  text-align: right;
  width: 100%;
  color: #ffffff;
}
.borderbottom {
  display: inline-block;
  width: 25%;
}
.tk_item {
  border-bottom: 1px solid #ffffff;
}
.borderbottom span:nth-child(1) {
  font-size: 30px;
}
.borderbottom span:nth-child(2) {
  font-size: 35px;
}
.pdfbox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}
.showPdf {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.back {
  position: relative;
  top: -150px;
  left: 150px;
}
.back_img {
  height: 100px;
  width: 100px;
}
.dutywaiver{
  p{
    display flex
    align-items center
    justify-content center
    border-radius 20px
    width 180px
    font-size: 32px
    background-color #C3E3F5
    color #A9A0AF
  }
}
.comlogo{
  .logo{
    width 200px
    height 80px
    margin-left 60px
    background-color white
    border-radius 0 0 12px 12px
    padding 10px
  }
  background-color color-primary
}
.copybtn{
  background-color: color-primary !important
  margin  14px 0
}
.navsetting{
  width 10vw
  height 30vw
  border-radius 10px 10px 0 0
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 50%
}
.navsetting::after{
  content: '分享'
  color white
  display block
  display flex
  justify-content center
  align-items center
  font-size 30px
  border-radius  0 0 10px 10px
  width 10vw
  letter-spacing 5px
  background-color color-primary
}
.navsetting1{
  width 10vw
  height 20vw
  border-radius 10px
  background-color black
  opacity 0.38
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
.backbtn{
  width 80%
  margin 0 auto
  border-bottom 0.8px solid rgba(255,255,255,0.5)
  display flex
  justify-content center
  align-items center
  span{
    width 100%
    display flex
    justify-content center
    align-items center
    color white
    font-size 50px
  }
}
.backtohome{
  width 80%
  margin 0 auto
  display flex
  justify-content center
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 50px
  }
}
.shareicon{
  width 80%
  margin 0 auto
  display flex
  flex-direction column
  justify-content center
  border-top 0.8px solid rgba(255,255,255,0.5)
  align-items center
  span{
    display flex
    justify-content center
    align-items center
    width 100%
    color white
    font-size 48px
  }
}

.navsetting2{
  width 10vw
  height 10vw
  border-radius 10px
  background-color color-primary
  position fixed
  right 10px
  z-index 999
  bottom 40%
}
input[type=number]{
    width: 1rem !important;
    height: 0.8rem !important;
    display: block;
    border: 1px solid #d3d3d3 !important;
    border-radius: 0.1rem;
    margin: 0 0.1rem !important;
    text-align: center;
}
input[type=range] {
    -webkit-appearance: none;
    background: white;
    border: 1px solid color-primary;
    height: 10px;
    border-radius: 10px; /*这个属性设置使填充进度条时的图形为圆角*/
    // margin-bottom: 40px;
    margin-right: 25px;
    width: 78%
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 45px;
    width: 45px;
    background: color-primary; 
    border-radius: 50%; 
    border: solid 0.125em rgba(205, 224, 230, 0.5); /*设置边框*/
    box-shadow: 0 .125em .125em #3b4547; /*添加底部阴影*/
}
.changend{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  span{
    font-size: 36px
  }
}
/deep/.md-slider-handle span {
    background-color: color-primary;
}
/deep/.md-slider::before {
    background-color: color-primary;
}
</style>
